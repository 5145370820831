<template>
  <div v-if="showFilters && showOptions() && showOptions().length" class="filter-group" data-cy="mailbox-filter-panel">
    <div class="flex justify-content-between">
      <h3>Filters</h3>
      <span class="material-icons-round grey mr-3 filter-close" @click="toggleFilters">close</span>
    </div>
    <div class="flex-1 flex flex-wrap align-items-center justify-content-start options-wrapper">
      <!-- Category Selection -->
      <div v-if="showOptions && showOptions().includes('category')" class="title-dropdown"
           data-cy="mailbox-filter-category">
        <label>Category:</label>
        <MultiSelect v-model="selectedCategory" :options="categoryOptions" showClear @change="updateCategoryFilter"
                     optionLabel="label" optionValue="label" placeholder="Select Category">
          <template #value="slotProps">
            {{ getCategoryValue(slotProps) }}
          </template>
        </MultiSelect>
      </div>
      <!-- Sender -->
      <div v-if="showOptions && showOptions().includes('sender')" class="title-dropdown" data-cy="mailbox-filter-sender">
        <label>Sender:</label>
        <MultiSelect v-model="selectedSender" :options="senderList" filter showClear @change="updateSenderFilter"
                     optionLabel="name" placeholder="Select Sender">
          <template #value="slotProps">
            {{ getSenderValue(slotProps) }}
          </template>
        </MultiSelect>
      </div>
      <!-- Recipient -->
      <div v-if="showOptions && showOptions().includes('recipient')" class="title-dropdown" data-cy="mailbox-filter-recipient">
        <label>Recipient:</label>
        <MultiSelect v-model="selectedSender" :options="recipientList" showClear @change="updateSenderFilter"
                     optionLabel="name" placeholder="Select Recipient">
          <template #value="slotProps">
            {{ getSenderValue(slotProps) }}
          </template>
        </MultiSelect>
      </div>
      <!-- Period -->
      <div v-if="showOptions && showOptions().includes('date')" class="title-dropdown" data-cy="mailbox-filter-period"><label>Date:</label>
        <Dropdown v-model="selectedPeriod" :options="dateOptions" @change="updateDateRange" showClear optionLabel="label"
                  optionValue="label" placeholder="Select Period"/>
      </div>
      <!-- Date From/To -->
      <div
          v-if="showOptions && showOptions().includes('date_from') && selectedPeriod && selectedPeriod === 'Select Date Range'"
          class="title-dropdown" data-cy="mailbox-date-filter-from"><label>Date Range (from):</label>
        <mailbox-date dateRef="mailDateRangeFrom"/>
      </div>
      <div
          v-if="showOptions && showOptions().includes('date_to') && selectedPeriod && selectedPeriod === 'Select Date Range'"
          class="title-dropdown" data-cy="mailbox-date-filter-to"><label>Date Range (to):</label>
        <mailbox-date dateRef="mailDateRangeTo"/>
      </div>
    </div>
  </div>
</template>
<script>
import {computed, ref, inject, onBeforeUnmount} from 'vue'
import {useStore} from 'vuex'
import MailboxDate from "@/components/common/filters/MailboxDate";
import dayjs from "dayjs";

export default {
  name: "MailboxFilterGroup",
  setup() {
    const store = useStore()
    const bus = inject('bus')
    const selectedCategory = ref([])
    const selectedSender = ref([])
    const selectedPeriod = ref()

    const showFilters = computed(() => store.getters.getElementVisibility('mailboxfilter'));

    const toggleFilters = () => {
        store.dispatch('updateElementVisibility', { "key": 'mailboxfilter', "value": !showFilters.value})
    }

    bus.on('resetMailFilters', () => {
      selectedSender.value = []
      selectedCategory.value = []
      selectedPeriod.value = null
    })

    const mailboxType = computed(() => store.getters.mailboxType)

    const showOptions = () => {
      const options = {
        inbox: ['category', 'sender', 'date', 'date_from', 'date_to'],
        sent: ['category', 'recipient', 'date', 'date_from', 'date_to'],
        drafts: ['category', 'recipient', 'date', 'date_from', 'date_to'],
        archived: [],
      }

      return options[mailboxType.value]
    }

    const categoryOptions = computed(() => store.getters.messageCategoryOptions)
    const senderOptions = computed(() => store.getters.senderOptions)

    const getCategoryValue = (slotProps) => {
      switch (true) {
        case selectedCategory.value && selectedCategory.value.length === categoryOptions.value.length:
          return 'All Categories'
        case selectedCategory.value && selectedCategory.value.length > 2:
          return 'Multiple'
        case selectedCategory.value && selectedCategory.value.length > 1:
          //pluck label and join
          return selectedCategory.value.join(", ")
        default:
          // this state reached at start and when deselecting all return placeholder
          return slotProps.value && slotProps.value.length ? slotProps.value[0] : slotProps.placeholder
      }
    }

    const getSenderValue = (slotProps) => {
      switch (true) {
        case selectedSender.value && selectedSender.value.length === senderOptions.value.length:
          return 'All Categories'
        case selectedSender.value && selectedSender.value.length > 2:
          return 'Multiple'
          case selectedSender.value && selectedSender.value.length > 1:
          //pluck label and join
          return selectedSender.value.map((sender) => { return sender.name }).join(", ")
        default:
          // this state reached at start and when deselecting all return placeholder
          return slotProps.value && slotProps.value.length ? slotProps.value[0].name : slotProps.placeholder
      }
    }

    const updateSenderFilter = (data) => {
      store.dispatch('updateCorrespondentFilter', data.value)
      //bus.emit('refreshMailbox', {mailbox: mailboxType.value, filter: 'sender', value: data.value})
    }

    const updateCategoryFilter = (data) => {
      store.dispatch('updateCategoryFilter', data.value)
      //bus.emit('refreshMailbox', {mailbox: mailboxType.value, filter: 'category', value: data.value})
    }

    const updateDateRange = (data) => {
      const today = dayjs()
      const end = today.toDate()
      let start
      switch (data.value) {
        case 'Last Week':
          start = today.subtract(1, 'week').toDate()
          store.dispatch('setMailDateRangeTo', end)
          store.dispatch('setMailDateRangeFrom', start)
          break
        case 'Last Month':
          start = today.subtract(1, 'month').toDate()
          store.dispatch('setMailDateRangeTo', end)
          store.dispatch('setMailDateRangeFrom', start)
          break
        case 'Last Year':
          start = today.subtract(1, 'year').toDate()
          store.dispatch('setMailDateRangeTo', end)
          store.dispatch('setMailDateRangeFrom', start)
          break
        case 'All':
          store.dispatch('setMailDateRangeTo', null)
          store.dispatch('setMailDateRangeFrom', null)
      }
      //bus.emit('refreshMailbox', {mailbox: mailboxType.value, filter: 'dateRange', value: {from: start, to: end}})
    }

    onBeforeUnmount(() => {
        bus.off('resetMailFilters')
    });

    return {
      showOptions,
      toggleFilters,
      getCategoryValue,
      getSenderValue,
      selectedCategory,
      selectedSender,
      selectedPeriod,
      dateOptions: computed(() => store.getters.dateOptions),
      senderList: computed(() => store.getters.senderList),
      recipientList: computed(() => store.getters.recipientList),
      categoryOptions,
      updateSenderFilter,
      updateCategoryFilter,
      updateDateRange,
      showFilters
    }
  },
  components: {
    MailboxDate
  }
}
</script>
<style scoped>
.filter-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--clarity-snow-grey);
  border-radius: 27px;
  padding: 20px;
}

.title-dropdown {
  display: flex;
  flex-direction: column;
  min-width: 33%;
  margin-bottom: 10px;
}

.title-dropdown label {
  margin: 10px 0px;
  font-weight: bold;
}

.title-dropdown:nth-of-type(1),
.title-dropdown:nth-of-type(4) {
  padding-right: 10px;
}

.title-dropdown:nth-of-type(2),
.title-dropdown:nth-of-type(5) {
  padding: 0px 10px;
}

.title-dropdown:nth-of-type(3),
.title-dropdown:nth-of-type(6) {
  padding-left: 10px;
}
</style>
