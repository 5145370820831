<template>
  <span class="wrapper">
    <Calendar id="basic" v-model="localDate" placeholder="dd/mm/yyyy" dateFormat="dd/mm/yy" autocomplete="off" @date-select="updateDateRange"  @input="updateDateRange" panel-class="calendar-valuationdate" :max-date="today" ref="datepicker">
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <div class="flex align-items-center justify-content-center m-2">
            <Button @click="setToToday" class="clarity-btn clarity-gradient-orange">Set As Today</Button>
          </div>
        </div>
      </template>
    </Calendar>
    <span class="material-icons-round calendar-icon grey" @click="triggerDatepicker">today</span>
  </span>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import dayjs from "dayjs";

export default {
  name: "MailboxDate",
  props:['dateRef'],
  setup(props) {
    const store = useStore()
    const localDate = ref(store.state.messages[props.dateRef])
    const today = dayjs().toDate();
    const datepicker = ref(null);

    const triggerDatepicker = () => {
      datepicker.value.onButtonClick();
    }

    const updateDateRange = (data) => {
      // check if valid date
      if(dayjs(data).isValid()) {
        switch (props.dateRef) {
          case 'mailDateRangeFrom':
            store.dispatch('setMailDateRangeFrom', data)
            break;
          case 'mailDateRangeTo':
            store.dispatch('setMailDateRangeTo', data)
            break;
        }
      }
    }

    const setToToday = () => {
      switch(props.dateRef) {
        case 'mailDateRangeFrom':
          store.dispatch('setMailDateRangeFrom', today)
          break;
        case 'mailDateRangeTo':
          store.dispatch('setMailDateRangeTo', today)
          break;
      }
    }

    return {
      datepicker,
      triggerDatepicker,
      localDate,
      updateDateRange,
      setToToday,
      today
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
}
.calendar-icon {
  position:absolute;
  top: calc(50% - 12px);
  right: 20px;
}
.wrapper:deep(.p-inputtext) {
  border-right: solid;
  border-right-color: var(--clarity-light-grey);
  border-right-width: 1px;
}
.wrapper:deep(.p-inputtext):enabled:focus,
.wrapper:deep(.p-inputtext):hover {
  border-right: solid;
  border-right-color: var(--clarity-grey);
  border-right-width: 1px;
}
.wrapper:deep(.p-inputwrapper) {
  width: 100%;
}
</style>
