<template>
  <div>
    <!-- div for toolbar above table   -->
    <div class="pb-5">
      <!-- SEARCH -->
      <div class="grid">
        <div class="col-12 xl:col-6" >
            <div class="flex justify-content-between align-items-center">
              <span class="p-input-icon-left cl-input full-width" style="max-width: 50%;">
                <i class="pi pi-search"></i>
                <InputText type="text" v-model="filters.global.value" placeholder="Search..." class="p-inputtext-lg w-full" data-cy="mailbox-search" />
              </span>
              <Button v-if="mailbox === 'inbox'" class="clarity-btn clarity-gradient-grey ml-4" @click="toggleUnread" >
                  <input type="checkbox" v-model="showUnread" disabled class="mr-3"/>
                  Show Unread
              </Button>
            </div>
        </div>
        <div class="col-12 xl:col-6">
            <div class="flex justify-content-between align-items-center">
              <Button v-if="mailbox === 'inbox'" class="clarity-btn clarity-gradient-grey text-dark-grey" data-cy="bulk-archive"
                      @click="bulkArchive()">{{ messageCount() }}
              </Button>
              <Button class="clarity-btn clarity-gradient-orange ml-4" data-cy="new-message" @click="$router.push('message')">New
                Message
              </Button>
            </div>
        </div>
      </div>
    </div>
    <div>
      <DataTable :value="messages" v-model:selection="selectedMessages" dataKey="message_id" ref="dt" :lazy="true" :paginator="true" :totalRecords="totalRecords"
                 :loading="mailboxLoading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" :rows="rows" responsiveLayout="scroll" class="rd-table" data-cy="messages-table"
                 :filters="filters" :globalFilterFields="['subject','category','sender.name','recipient.name']" @row-click="triggerClick"
                 :rowClass="(data) => data.read === 0 ? 'rowclass1': null"
      >
        <Column selectionMode="multiple" headerStyle="width: 3em" ></Column>
        <Column v-if="mailbox === 'inbox'|| mailbox === 'archived'" header="From" field="sender"
                filterField="sender.name"
                :sortable="true"
                class="click-trigger hide-column"
        >
          <template #body="slotProps">
            {{ slotProps.data.sender.name }}
          </template>
        </Column>
        <Column v-if="mailbox === 'sent' || mailbox === 'drafts'" header="Recipient" field="recipient"
                filterField="recipient.name" :sortable="true" class="click-trigger hide-column">
          <template #body="slotProps">
            {{ slotProps.data.recipient.name }}
          </template>
        </Column>
        <Column field="subject" :sortable="true" class="click-trigger">
          <template #header>
              <span class="hide-column-title">Message</span>
              <span class="hide-column">Subject</span>
          </template>
          <template #body="slotProps" @click="goToMessage(slotProps.data, false)">
            <span class="hide-column-title click-trigger">
              <span v-if="mailbox === 'inbox'|| mailbox === 'archived'" class="click-trigger">From: {{ slotProps.data.sender.name }}<br /></span>
              <span v-if="mailbox === 'sent' || mailbox === 'drafts'" class="click-trigger">To: {{ slotProps.data.recipient.name }}<br /></span>
              <b class="click-trigger" >{{ slotProps.data.subject }}</b><br />
              Category: {{ slotProps.data.category }}<br />
            </span>
            <span class="bold click-trigger hide-column">{{ slotProps.data.subject }}</span>
          </template>
        </Column>
        <Column header="Category" field="category" :sortable="true" class="click-trigger hide-column"></Column>
        <Column field="attachments" class="click-trigger hide-column">
          <template #body="slotProps">
            <span v-if="slotProps.data.attachments> 0" class="material-icons-round dark-grey">attachment</span>
          </template>
        </Column>
        <Column :header="getTimeStampHeader()" field="date_sent" filterField="date_sent" :sortable="true">
          <template #body="slotProps">
            <div class="flex flex-wrap align-items-center justify-content-start">
              <div class="flex flex-column">
                <span>{{ getDate(slotProps.data.date_sent) }}</span>
                <span>{{ getTime(slotProps.data.date_sent) }}</span>
              </div>
              <span v-if="slotProps.data.attachments> 0" class="material-icons-round dark-grey hide-column-title pl-1">attachment</span>
            </div>
          </template>
        </Column>
        <Column v-if="mailbox === 'sent'" header="Read By Recipient">
          <template #body="slotProps">
            <div class="flex flex-column" v-if="slotProps.data.lastOpened !== null">
              <span>{{ getDate(slotProps.data.lastOpened) }}</span>
              <span>{{ getTime(slotProps.data.lastOpened) }}</span>
            </div>
          </template>
        </Column>
        <Column v-if="mailbox === 'sent' && deleteAccess" header="Delete">
            <template #body="slotProps">
                <span @click="openDeleteModal(slotProps.data)" class="material-icons hand-pointer grey ml-3" v-tooltip.top="'Delete Message'">delete_forever</span>
            </template>
        </Column>
        <Column header="Actions" v-if="mailbox !== 'sent'" @click.stop>
          <template #body="slotProps">
            <div class="flex justify-content-around" style="width: 100%">
              <div class="message-action" :class="[cursorBusy.read.includes(slotProps.data.message_id) ? 'busy' : '']" data-cy="action-mark-read"
                   v-if="slotProps.data.read === 0 && mailbox === 'inbox'" >
                  <ProgressSpinner @click.stop strokeWidth="4"  animationDuration="1s" class="size" style="width: 23px; height: 23px;" v-if="cursorBusy.read.includes(slotProps.data.message_id)"/>
                  <span v-else class="material-icons-round grey" @click.stop="markAsRead(slotProps.data)" v-tooltip.top="'Mark as Read'">markunread</span>
              </div>
              <div class="message-action" :class="[cursorBusy.read.includes(slotProps.data.message_id) ? 'busy' : '']" data-cy="action-mark-unread"
                   v-if="slotProps.data.read === 1  && mailbox === 'inbox'" >
                  <ProgressSpinner @click.stop strokeWidth="4"  animationDuration="1s" class="size" style="width: 23px; height: 23px;" v-if="cursorBusy.read.includes(slotProps.data.message_id)"/>
                  <span v-else class="material-icons-round grey" @click.stop="markAsUnread(slotProps.data)" v-tooltip.top="'Mark as Unread'">mark_as_unread</span>
              </div>
              <div class="message-action" :class="[cursorBusy.archive.includes(slotProps.data.message_id) ? 'busy' : '']" data-cy="action-archive"
                   v-if="mailbox !== 'archived'" >
                <ProgressSpinner @click.stop strokeWidth="4"  animationDuration="1s" class="size" style="width: 23px; height: 23px;" v-if="cursorBusy.archive.includes(slotProps.data.message_id)"/>
                <span v-else>
                    <span v-if="mailbox == 'drafts'" class="material-icons-round grey" @click.stop="deleteDraft(slotProps.data.message_id)" v-tooltip.top="'Delete Message'">delete_forever</span>
                    <span v-else class="material-icons-round grey" @click.stop="archiveMessage(slotProps.data.message_id)" v-tooltip.top="'Archive Message'" >archive</span>
                </span>
              </div>
              <div class="message-action" :class="[cursorBusy.archive.includes(slotProps.data.message_id) ? 'busy' : '']" data-cy="action-unarchive"
                   v-if="mailbox === 'archived'" >
                <ProgressSpinner @click.stop strokeWidth="4"  animationDuration="1s" class="size" style="width: 23px; height: 23px;" v-if="cursorBusy.archive.includes(slotProps.data.message_id)"/>
                <span v-else class="material-icons-round grey" @click.stop="unarchiveMessage(slotProps.data.message_id)" v-tooltip.top="'Unarchive Message'">unarchive</span>
              </div>
              <div class="message-action" data-cy="action-reply"
                   v-if="!['drafts', 'sent'].includes(mailbox)"
                   v-tooltip.top="'Reply'">
                <span class="material-icons-round grey" @click.stop="goToMessage(slotProps.data, true)">reply</span>
              </div>
            </div>
          </template>
        </Column>
        <template #paginatorstart>
          <!-- Needed to centralise paginator -->
        </template>
        <template #paginatorend>
          <div class="my-2"><span class="mr-2">Messages Per Page:</span>
            <Dropdown v-model="rows" :options="[5,10,20]" class="pagination-options"/>
          </div>
        </template>
        <template #empty>
          <div class="flex justify-content-center">No messages found.</div>
        </template>
      </DataTable>
      <Dialog v-model:visible="showConfirmDelete" class="add-error-dialog">
          <div class="flex justify-content-between modal-header">
              <h3 class="blue">Confirm Delete</h3>
          </div>
          <div class="message p-3">Are you sure you want to delete this message? this action cannot be reversed.</div>
          <div class="flex btn-group pt-3 flex-row-reverse">
              <div>
                  <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
              </div>
              <div>
                  <submit-button @submitted="confirmDelete" :loading="loadingState" button-text="Confirm Action" loading-text="Removing Message..." ></submit-button>
              </div>
          </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import api from '@/core/services/api'
import { FilterMatchMode, FilterOperator } from "primevue/api";
import {computed, ref, watch, inject, onMounted, onBeforeUnmount} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {debounce} from "lodash";
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";

export default {
  name: "MessagesTable",
  components: {SubmitButton},
    setup() {
    const store = useStore()
    const router = useRouter()
    const bus = inject('bus')
    const selectedMessages = ref()
    const rows = ref(20)
    const correspondentFilter = computed(() => store.getters.getCorrespondentFilter)
    const mailbox = computed(() => store.getters.mailboxType)
    const mailFrom = computed(() => store.getters.mailDateRangeFrom)
    const mailTo = computed(() => store.getters.mailDateRangeTo)
    const mailFromDate = ref()
    const mailToDate = ref()
    const senderFilter = ref()
    const recipientFilter = ref()
    const cursorBusy = ref({ read: [], archive: [] })
    const showUnread = ref(false)
    const targetUUID = computed(() => store.getters.targetUUID)
    const loadingState = computed(() => store.getters.isLoading('DeleteSecureMessage'))

    const showConfirmDelete = ref(false)
    const deleteMessageID = ref()
    const closeModal = () => {
        showConfirmDelete.value = false
    }
    const openDeleteModal = (data) => {
        deleteMessageID.value = data.message_id
        showConfirmDelete.value = true
    }

    const confirmDelete = async () => {
        await store.dispatch('setLoading', {id: 'DeleteSecureMessage', state: true})

        if (process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
        }
        const response = await api.post('/securemessaging/removeMessage', JSON.stringify({"messageID": deleteMessageID.value}))

        if (response.success) {
            await store.dispatch('setLoading', {id: 'DeleteSecureMessage', state: false})
            refineMessages(lastLazyParams.value)
            showConfirmDelete.value = false
        }else{
            await store.dispatch('addNotification', {
                severity: 'error',
                summary: 'Remove Message',
                detail: `Failed to remove the secure message.`
            })
            await store.dispatch('setLoading', {id: 'DeleteSecureMessage', state: false})
        }

    }

    const filters = ref({
      'subject': {value: '', matchMode: 'contains'},
      'sender.name': {
        value: computed(() => senderFilter.value),
        matchMode: FilterMatchMode.IN
      },
      'recipient.name': {
        value: computed(() => recipientFilter.value),
        matchMode: FilterMatchMode.IN
      },
      'category': {
        value: computed(() => store.getters.getCategoryFilter),
        matchMode: FilterMatchMode.IN
      },
      'date_sent': {
        operator: FilterOperator.AND,
        constraints: [
          {value: computed(() => mailFromDate.value), matchMode: FilterMatchMode.GREATER_THAN},
          {value: computed(() => mailToDate.value), matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO}
        ]
      },
      'global': {value: '', matchMode: 'contains'}
    })

    const getDate = (timestamp) => {
      return dayjs(timestamp).format('DD-MM-YYYY')
    }

    const getTime = (timestamp) => {
      return dayjs(timestamp).format('HH:mm:ss')
    }

    watch(correspondentFilter, (val) => {
      if (mailbox.value === 'inbox' || mailbox.value === 'archived') {
        senderFilter.value = val
      }
      if (mailbox.value === 'sent' || mailbox.value === 'drafts') {
        recipientFilter.value = val
      }
    })

    watch(mailFrom, val => {
      if (val !== null) {
        mailFromDate.value = dayjs(val).format()
      } else {
        mailFromDate.value = val
      }
    })
    watch(mailTo, val => {
      if (val !== null) {
        mailToDate.value = dayjs(val.value).add(1, 'day').format()
      } else {
        mailToDate.value = val
      }
    })

    const messageCount = () => {
      let btnText = mailbox.value !== 'archived' ? "Archive " : "Unarchive "
      if (selectedMessages.value && selectedMessages.value.length) {
        btnText += `(${selectedMessages.value.length})`
      }
      return btnText
    }

    const goToMessage = async (message, isReply) => {
      const messageID = message.message_id;
      // if we are opening an inbox or archive message, we need to call update to mark it as opened.
      if((mailbox.value === 'inbox' || mailbox.value === 'archive')){
          // only trigger open when it is a client or a staff member opening a staff message.
          if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
              if(targetUUID.value && targetUUID.value.startsWith('c_')) {
                  store.dispatch('confirmOpenMessage', {messageID: messageID, open: true, send: false})
                  store.dispatch('updateMessage', {messageID: messageID, read: 1, send: false, suppress: true})
              }
          }else{
              store.dispatch('confirmOpenMessage', {messageID: messageID, open: true, send: false})
              if(message.lastOpened == null || message.lastOpened == 'null'){
                  store.dispatch('openNewMessage')
              }
              store.dispatch('updateMessage', {messageID: messageID, read: 1, send: false, suppress: true})
          }
      }
      router.push('message/' + messageID)
      const loaded = await store.dispatch('loadMessageWithID', {id: messageID, isReply: isReply})
      if (loaded && isReply === true) {
        if (loaded.sender) {
          bus.emit('recipientSelected', loaded['sender'])
        }
        bus.emit('messageIsReply')
        setTimeout(function () {
            bus.emit('messageIsReply')
        } , 600);
      }
    }

    const markAsRead = async (message) => {
      cursorBusy.value.read.push(message.message_id)
      await store.dispatch('updateMessage', {messageID: message.message_id, read: 1, send: false})
      message.read = 1;
    }

    const markAsUnread = async (message) => {
      cursorBusy.value.read.push(message.message_id)
      await store.dispatch('updateMessage', {messageID: message.message_id, read: 0, send: false})
      message.read = 0;
    }

    const archiveMessage = async (messageID) => {
      cursorBusy.value.archive.push(messageID)
      await store.dispatch('updateMessage', {messageID: messageID, archived: 1, send: false})
      refineMessages(lastLazyParams.value)
    }

    const deleteDraft = async (messageID) => {
        cursorBusy.value.archive.push(messageID)
        await store.dispatch('deleteDraft', {messageID: messageID})
        refineMessages(lastLazyParams.value)
    }

    const unarchiveMessage = async (messageID) => {
      cursorBusy.value.archive.push(messageID)
      await store.dispatch('updateMessage', {messageID: messageID, archived: 0, send: false})
      refineMessages(lastLazyParams.value)
    }

    const bulkArchive = async () => {
      if (selectedMessages.value.length > 1) {
        selectedMessages.value.forEach((message) => {
            cursorBusy.value.archive.push(message.message_id)
        })
        await store.dispatch('bulkMessageArchive', selectedMessages.value)
        selectedMessages.value = []
      } else {
        cursorBusy.value.archive.push(selectedMessages.value[0].message_id)
        archiveMessage(selectedMessages.value[0].message_id)
        selectedMessages.value = []
      }
    }

    bus.on('unBusyCursorRead', (id) => {
        cursorBusy.value.read = cursorBusy.value.read.filter(value => value !== id);
    })
    bus.on('unBusyCursorArchive', (idArray) => {
        idArray.forEach((id) => {
            cursorBusy.value.archive = cursorBusy.value.archive.filter(value => value !== id);
        })
        refineMessages(lastLazyParams.value)
    })

    bus.on('bulkArchiveError', (subject) => {
      store.dispatch('addNotification',{severity: 'error', summary: 'Bulk Archive Error', detail: `Not able to archive message with subject: ${subject} - please archive individually.`, life: 6000})
    })

    const getTimeStampHeader = () => {
      let header
      switch (mailbox.value) {
        case 'inbox':
        case 'archived':
          header = 'Received'
          break
        case 'drafts':
          header = 'Saved'
          break
        case 'sent':
          header = 'Sent'
          break
      }
      return header
    }

    const onPage = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };
    const onSort = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };
    const onFilter = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };

    const lazyParams = ref({
        "page": 0
    })
    const lastLazyParams = ref({
        "page": 0
    })
    const loading = ref(false);
    const loadLazyData = async () => {
        lastLazyParams.value = lazyParams.value
        await refineMessages(lazyParams.value)
    }

    bus.on('refreshMailbox', () => {
        refineMessages(lastLazyParams.value)
    })

    watch(rows, () => {
        refineMessages(lastLazyParams.value)
    })

    watch(filters.value, debounce(() => {
        refineMessages(lastLazyParams.value)
    }, 500))

    watch(showUnread, () => {
        refineMessages(lastLazyParams.value)
    })


    const refineMessages =  async (params = null) => {

        const page = params.page+1

        // looking to build the secure messaging filtered payload.
        const mailboxValue = (mailbox.value == 'archived') ? 'archive' : mailbox.value;
        const payload = {
            "page": page,
            "per_page": rows.value,
            "mailbox": mailboxValue
        }

        const filter = [];

        if (params.sortField)  {
            payload.sort = {
                "by": params.sortField,
                "order": (params.sortOrder == 1) ? "asc" : "desc"
            }
        }

        //  determine from store values
        let correspondent  = store.getters.getCorrespondentFilter
        let category = store.getters.getCategoryFilter
        let end = mailToDate.value
        let start = mailFrom.value
        let dateRange = start && end

        if (correspondent.length) {
            // sender/recipient
            // confirm the field we want to filter by.
            let field = 'recipient'
            if(mailbox.value === 'inbox'|| mailbox.value === 'archived'){
                field = 'sender'
            }
            filter.push({
                "field": field,
                "operator": "in",
                "query": correspondent.map((person) => { return person.uuid })
            })
        }
        if (category.length) {
            filter.push({
                "field": "category",
                "operator": "in",
                "query": category
            })
        }
        if (dateRange) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            filter.push({
                "field": "date_sent",
                "operator": "between",
                "query": {
                    "from": startDate.toISOString().split('T')[0],
                    "to": endDate.toISOString().split('T')[0]
                }
            })
        }
        if (filters.value.global.value) {
            filter.push({
                "field": "subject",
                "operator": "like",
                "query": filters.value.global.value
            })
        }

        if(showUnread.value && mailbox.value === 'inbox') {
            filter.push({
                "field": 'read',
                "operator": "=",
                "query": 0
            })
        }

        if(filter.length > 0){
            payload.filter = filter;
        }

        await store.dispatch('refineMessages', payload)
    }

    onMounted(() => {
      store.dispatch('updateCorrespondentFilter', [])
      store.dispatch('updateCategoryFilter', [])
      loadLazyData()
    })

    const triggerClick = (event) => {
        if(event.originalEvent.target.className.includes('click-trigger')){
            goToMessage(event.data, false)
        }
    }

    const toggleUnread = () => {
        showUnread.value = !showUnread.value
    }

    const deleteAccess = computed(() => {
        const accessToken = store.state.auth.cognitoUser.signInUserSession.accessToken;
        if(accessToken && accessToken.payload && accessToken.payload['cognito:groups']){
            if(accessToken.payload['cognito:groups'].includes('securemessaging-delete')){
                return true;
            }
        }
        return false;
    });

    onBeforeUnmount(() => {
        bus.off('unBusyCursorRead')
        bus.off('unBusyCursorArchive')
        bus.off('bulkArchiveError')
        bus.off('refreshMailbox')
    });

    return {
      filters,
      mailbox,
      rows,
      selectedMessages,
      cursorBusy,
      archiveMessage,
      bulkArchive,
      getDate,
      getTime,
      getTimeStampHeader,
      goToMessage,
      markAsRead,
      markAsUnread,
      messageCount,
      unarchiveMessage,
      mailboxLoading: computed(() => store.getters.mailboxLoadingState),
      onPage,
      onSort,
      onFilter,
      messages: computed(() => store.getters.messages),
      loading,
      totalRecords: computed(() => store.getters.messageMeta.total_count),
      triggerClick,
      showUnread,
      toggleUnread,
      deleteAccess,
      closeModal,
      openDeleteModal,
      showConfirmDelete,
      loadingState,
      confirmDelete,
      deleteDraft
    }
  }
}
</script>

<style scoped>
.half {
  width: 30%;
}

.clarity-btn {
  padding: 15px 45px;
}

.text-dark-grey {
  color: var(--clarity-dark-grey);
}

.message-action:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(51%) saturate(3766%) hue-rotate(195deg) brightness(85%) contrast(101%);
}

.busy > span:hover {
  cursor: wait !important;
}



:deep(.rowclass1) {
    color: var(--clarity-pure-white) !important;
    background-color: var(--clarity-light-blue) !important;
}

:deep(.rowclass1 .material-icons-round){
    color: var(--clarity-pure-white) !important;
}

:deep(.click-trigger) {
    cursor: pointer;
}


</style>
