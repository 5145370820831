<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title" :data-cy="`secure-messaging-${type}`">{{ type }}</h2></template>
        <template #content>
          <div class="flex align-items-center">
            <span class="mr-3">Filters:</span>
            <Button class="clarity-btn round grey clarity-gradient-grey"
                    @click="toggleFilters" :disabled="checkDisabled()" data-cy="mailbox-filter-toggle">
              <span class="material-icons-round blue">filter_alt</span>
            </Button>
          </div>
        </template>
        <template #filters>
          <MailboxFilterGroup />
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div v-if="type === 'inbox'">
        <MessagesTable />
      </div>
      <div v-if="type === 'drafts'">
        <MessagesTable />
      </div>
      <div v-if="type === 'sent'">
        <MessagesTable />
      </div>
      <div v-if="type === 'archived'">
        <MessagesTable />
      </div>
    </template>
  </content-card-full>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import CardTitle from "@/components/common/CardTitle"
import ContentCardFull from "@/components/common/ContentCardFull"
import MailboxFilterGroup from "@/components/common/filters/MailboxFilterGroup";
import MessagesTable from "@/components/secure_messages/MessagesTable";

export default {
  name: "Mailbox",
  components: {
    CardTitle,
    ContentCardFull,
    MailboxFilterGroup,
    MessagesTable
  },
  setup() {
    const store = useStore()

    onMounted(() => {
      getRecipients()
      getSenderList()
      getRecipientFilterOptions()
    })

    /*
    const getInbox = () => {
      store.dispatch('loadMessages')
    }

    const getSent = () => {
      store.dispatch('loadSentMessages')
    }
     */

    const getRecipients = () => {
      store.dispatch('getRecipientList')
    }

    const getRecipientFilterOptions = () => {
        store.dispatch('getRecipientFilterOptions')
    }

    const getSenderList = () => {
      store.dispatch('getSenderList')
    }

    const showFilters = computed(() => store.getters.getElementVisibility('mailboxfilter'));

    const toggleFilters = () => {
        store.dispatch('updateElementVisibility', { "key": 'mailboxfilter', "value": !showFilters.value})
    }

    const checkDisabled = () => {
      const tab = computed(() => store.getters.mailboxType)
      return tab.value === 'archived'
    }

    return {
      type: computed(() => store.getters.mailboxType),
      toggleFilters,
      checkDisabled,
    }
  }
}
</script>

<style scoped>

</style>
